import * as actions from './actions';
import * as pages from './pages';
import * as constants from '../constants';
import { isAppsManagerEnabled } from '../../utils/experiments';

const getRouterActions = (t, hasCustomPages) => ({
  showOnAllModes: true,
  default: [
    actions.getAddPageAction(t),
    actions.VIEW_SITE_MEMBERS,
    actions.OPEN_SIGNUP_SETTINGS,
    actions.getAdvancedURLSettingsAction(t),
    hasCustomPages && actions.getDeleteCustomPageAction(t),
    actions.getDeleteMembersAreaAction(t),
  ].filter((a) => !!a),
});

const createAppManifest = async (editorSDK, t) => {
  const shouldAddAppsManagerManifest = await isAppsManagerEnabled();
  const appDescriptorManifest = {
    appDescriptor: {
      mainActions: [
        {
          title: t('Apps_Manager_Manage_Member_Pages'),
          actionId: 'openMembersAreaPagesPanel',
          type: 'editorActions',
        },
        {
          title: t('Apps_Manager_Add_Member_Page'),
          actionId: 'createBlankPage',
          type: 'editorActions',
        },
      ],
      customActions: [
        {
          title: t('Apps_Manager_Open_Badges_Page'),
          actionId: 'openBadgesPage',
          type: 'dashboard',
        },
        {
          title: t('Apps_Manager_Open_Members_Add_Panel'),
          actionId: 'openMembersAddPanel',
          type: 'editorActions',
        },
        {
          title: t('Apps_Manager_Engage_With_Your_Members'),
          actionId: 'openSiteMembersDashboard',
          type: 'dashboard',
        },
        {
          title: t('Apps_Manager_Explore_Members_Area'),
          actionId: 'navigateToMembersArea',
          type: 'editorActions',
        },
      ],
    },
  };

  return {
    ...(shouldAddAppsManagerManifest ? appDescriptorManifest : {}),
    controllersStageData: {
      members: {
        default: {
          visibility: 'NEVER',
        },
      },
    },
    pages: {
      pageDescriptors: {
        default: { icon: 'membersAction' },
      },
      applicationSettings: {
        default: {
          displayName: t('Pages_Panel_MemberMenu_Title'),
          helpId: constants.MEMBERS_PAGES_PANEL_HELP_ID,
        },
      },
      applicationActions: getRouterActions(t, false),
      pageSettings: {
        default: [pages.PAGE_INFO, pages.PERMISSIONS, pages.SEO],
      },
      pageActions: {
        default: [],
        deletable: [
          {
            title: t('Pages_Panel_MemberMenu_Actions_Page_Delete'),
            icon: 'deleteAction',
            event: 'removePage',
          },
        ],
        readOnly: [],
      },
    },
  };
};

export { createAppManifest };
